<template>
  <div v-if="isOpen" class="side-bar-back" @click="toggleIsOpen"></div>

  <div :class="['side-bar-wrapper', isOpen ? 'open' : '']">
    <section class="top-section">
      <button @click="toggleIsOpen">
        <img src="/icons/side-bar-uturn-3bar.svg" class="w-[20px]" />
      </button>
    </section>

    <!--First-->
    <section class="menu-section" :class="{ open: !isSecondMenu && isOpen, close: isSecondMenu }">
      <div>
        <RouterLink
          v-for="menuItem in mainLink"
          :key="menuItem.to"
          :to="menuItem.to"
          @click="
            () => {
              if (menuItem.key === 'robots') {
                toggleIsOpen()
              }
            }
          "
        >
          <p
            class="PG3 menu-wrapper"
            :class="menu === menuItem.key ? 'bg-gray-back' : 'bg-white'"
            @click="
              () => {
                menu = menuItem.key
              }
            "
          >
            {{ menuItem.text }}
            <icon icon="heroicons:chevron-right-20-solid" width="18"></icon>
          </p>
        </RouterLink>
      </div>

      <RouterLink to="/contact">
        <button
          class="contact-us PG3"
          @click="
            () => {
              menu = 'contact'
              toggleIsOpen()
            }
          "
        >
          Contact Us
        </button>
      </RouterLink>
    </section>

    <!--Second-->
    <section class="category-section">
      <button
        class="menu-wrapper PG3 bg-gray-back"
        @click="
          () => {
            menu = ''
          }
        "
      >
        <p class="PG3">Main menu</p>
        <img src="/icons/side-bar-uturn-arrow.svg" />
      </button>

      <div v-if="menu === 'control'">
        <RouterLink
          v-for="link in robotsLink"
          :to="link.to"
          class="second-category flex PG3"
          @click="toggleIsOpen"
        >
          {{ link.text }}
        </RouterLink>
      </div>

      <div v-else-if="menu === 'samples'">
        <template v-for="(category, key) in samplesLink">
          <button
            class="first-category PG3"
            :class="{ click: broadcastMenu === key }"
            @click="
              () => {
                broadcastMenu = key
              }
            "
          >
            <p>{{ key }}</p>
            <img v-if="broadcastMenu === key" src="/icons/side-bar-up-arrow.svg" />
            <img v-else src="/icons/side-bar-down-arrow.svg" />
          </button>

          <div class="accordion-category" :class="{ closed: broadcastMenu !== key }">
            <div class="flex flex-col">
              <RouterLink
                v-for="link in category"
                v-if="broadcastMenu === key"
                class="PG3 second-category"
                :key="link.to"
                :to="link.to"
                @click="toggleIsOpen"
              >
                {{ link.text }}
              </RouterLink>
            </div>
          </div>
        </template>
      </div>

      <div v-else-if="menu === 'usecase'">
        <RouterLink
          v-for="link in useCaseLink"
          :to="link.to"
          class="second-category flex PG3"
          @click="toggleIsOpen"
        >
          {{ link.text }}
        </RouterLink>
        <a
          href="https://www.youtube.com/playlist?list=PL-z_qfVVum10Ti4il6k9xjfVqaP57z8EG"
          target="_blank"
        >
          <button class="second-category flex PG3">Video</button>
        </a>
      </div>

      <div v-else-if="menu === 'co-drive'">
        <RouterLink
          v-for="link in codriveLink"
          :to="link.to"
          class="second-category flex PG3"
          @click="toggleIsOpen"
        >
          {{ link.text }}
        </RouterLink>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue'
import { mainLink, robotsLink, samplesLink, useCaseLink, codriveLink } from '@/router/navigation'
import { useRoute } from 'vue-router'
import { Icon } from '@iconify/vue'

type Props = {
  isOpen: boolean
}
const props = defineProps<Props>()
props.isOpen

const emit = defineEmits(['close'])
const toggleIsOpen = () => {
  emit('close')
}

const menu = ref<string>('robots')
const broadcastMenu = ref<string>('')

const route = useRoute()

watch(
  () => route.path,
  () => {
    menu.value = getCategoryFromRoute()
    broadcastMenu.value = getMenuFromRoute()
  }
)

onMounted(() => {
  menu.value = getCategoryFromRoute()
  broadcastMenu.value = getMenuFromRoute()
})

function getCategoryFromRoute() {
  const path = route.path
  const category = path.split('/')[1]
  return category
}

function getMenuFromRoute() {
  const path = route.path
  const category = path.split('/')[2]
  if (category === 'broadcast') return 'Broadcast'
  else if (category === 'chat') return 'Chat'
  else if (category === 'control') return 'Control Type'
  else if (category === 'applications') return 'Applications'
  return category
}

const isSecondMenu = computed(() => {
  return menu.value !== 'robots' && menu.value !== 'contact' && menu.value !== ''
})
</script>

<style scoped>
button {
  @apply w-full;
}
.side-bar-wrapper {
  @apply fixed top-0 w-[270px] h-screen z-[10001] transition-all duration-300 bg-white rounded overflow-y-auto;
  left: calc(-270px);
}
.side-bar-wrapper.open {
  @apply left-0 transition-all duration-300;
}
.side-bar-back {
  @apply z-[10001] bg-black fixed top-0 left-0 w-full h-screen opacity-50 transition-all duration-300;
}
.top-section {
  @apply flex items-center justify-between h-[55px] px-[16px] border-b border-[#E9E9E9];
}

.menu-section {
  @apply fixed top-[60px] bg-white px-[17px] py-[4px] flex flex-col justify-between gap-[4px] transition-all duration-300 w-[270px] h-[calc(100vh-70px)];
  left: calc(-270px);
}
.menu-section.open {
  @apply fixed top-[60px] left-0;
}
.menu-section.close {
  @apply fixed  top-[60px];
  left: calc(-270px);
}

.menu-wrapper {
  @apply px-[12px] py-[14px] flex justify-between items-center rounded-[4px];
}
.menu-wrapper.click {
  @apply bg-gray-back;
}
.menu-wrapper:hover {
  @apply bg-gray-back;
}

.category-section {
  @apply bg-white px-[17px] py-[4px] flex flex-col gap-[4px] transition-all duration-300 w-[270px];
}
.first-category {
  @apply px-[12px] py-[14px] flex justify-between items-center rounded-[4px];
}
.first-category.click {
  @apply bg-gray-back;
}

.second-category {
  @apply p-[14px] rounded-[4px];
}
.second-category.click {
  @apply bg-gray-back;
}
.accordion-category {
  @apply px-[12px] max-h-[1000px] overflow-hidden transition-all duration-500 ease-in-out;
}
.accordion-category.closed {
  @apply max-h-0;
}

.contact-us {
  @apply border border-green-100 rounded-full px-[22px] py-[10px] flex justify-center items-center bg-white text-green-100;
}
.contact-us:hover {
  @apply text-white bg-green-200 border-green-200 transition-all duration-300;
}
</style>
