<template>
  <div class="footer-wrapper">
    <img class="w-[100px]" src="/logo/logo-gray.svg" />
    <div>
      <p class="PG3 text-gray-400 hidden lg:block">
        TeamGRIT | CEO : Kim KiRyeung | 1302, 8, Seongnam-daero 331beon-gil, Bundang-gu,
        Seongnam-si, Gyeonggi-do, 13558, Rep. of korea
        <br />
        E-MAIL : admin@teamgrit.kr | TEL : +82 70-8866-8266
      </p>
      <p class="PG4 text-gray-400 lg:hidden">
        TeamGRIT | CEO : Kim KiRyeung | 1302, 8, Seongnam-daero 331beon-gil, Bundang-gu,
        Seongnam-si, Gyeonggi-do, 13558, Rep. of korea
        <br />
        E-MAIL : admin@teamgrit.kr | TEL : +82 70-8866-8266
      </p>
    </div>
    <p class="PG3 text-gray-400 hidden lg:block">ⓒ 2023 TeamGRIT All rights reserved</p>
    <p class="PG4 text-gray-400 lg:hidden">ⓒ 2023 TeamGRIT All rights reserved</p>
    <p class="PG3 text-gray-400 flex justify-end">v{{ version }}</p>
  </div>
</template>

<script setup lang="ts">
const version = import.meta.env.VITE_PROJECT_VERSION
</script>

<style scoped>
.footer-wrapper {
  @apply flex flex-col leading-loose border-t gap-[21px] py-[44px] px-[28px] lg:gap-[30px] lg:px-20 lg:pt-[80px] lg:pb-[30px];
}
</style>
