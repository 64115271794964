<template>
  <button class="btn" :class="[size, sizeClass]" :disabled="disabled || isLoading">
    <icon v-if="isLoading" icon="eos-icons:bubble-loading" width="23"></icon>
    <icon
      v-if="iconConfig && !isLoading"
      :icon="iconConfig.icon"
      :color="iconConfig.color"
      :width="iconConfig.width"
      :class="{
        'mr-2': text
      }"
    />
    <img
      v-if="image && !isLoading"
      :src="image"
      :class="{
        'mr-2': text
      }"
    />
    <p v-if="text && !isLoading">
      {{ text }}
    </p>
  </button>
</template>

<script setup lang="ts">
interface Props {
  text?: string
  iconConfig?: Icon
  image?: string
  size?: 'large-2x' | 'large-x' | 'large' | 'medium' | 'small' | 'tiny'
  disabled?: boolean
  isLoading?: boolean
}

const props = defineProps<Props>()
props.size
const sizeClass =
  props.size === 'large-2x'
    ? 'H4'
    : props.size === 'large-x'
    ? 'PG2'
    : props.size === 'large'
    ? 'PG3'
    : props.size === 'medium'
    ? 'PG3'
    : props.size === 'small'
    ? 'PG4'
    : props.size === 'tiny'
    ? 'PG4'
    : props.size
</script>

<style>
.btn {
  @apply cursor-pointer text-gray-900 py-[12px] px-[24px] rounded-[4px] border border-gray-200 flex items-center justify-center transition-all duration-100 ease-in-out whitespace-nowrap;
}

.btn:hover {
  @apply text-gray-900 bg-gray-back border-gray-200;
}

.btn:disabled {
  @apply text-gray-300 cursor-not-allowed border-gray-200 bg-gray-200;
}

.btn.primary {
  @apply text-white bg-green-100 border-green-100;
}

.btn.primary:hover {
  @apply text-white bg-green-200 border-green-200;
}

.btn.primary:disabled {
  @apply text-gray-300 cursor-not-allowed border-gray-200 bg-gray-200;
}

.btn.secondary {
  @apply text-green-100 bg-white border-green-100;
}

.btn.secondary:hover {
  @apply text-green-200 bg-white border-green-200;
}

.btn.secondary:disabled {
  @apply text-gray-300 cursor-not-allowed border-gray-200 bg-gray-200;
}

.btn.large-2x {
  @apply px-[24px] h-[64px] /* H4 */;
}

.btn.large-x {
  @apply px-[24px] h-[54px] /* PG2 */;
}

.btn.large {
  @apply px-[20px] h-[48px] /* PG3 */;
}

.btn.medium {
  @apply px-[16px] h-[40px] /* PG3 */;
}

.btn.small {
  @apply px-[12px] h-[36px] /* PG4 */;
}

.btn.tiny {
  @apply px-[8px] h-[30px] /* PG4 */;
}

.btn.clear {
  padding: 0;
  border-radius: 0;
  border: none;
  margin: 0;
  background-color: white;
}

/* .btn:active {
  @apply mt-[1px];
} */
</style>
