<template>
  <header>
    <nav class="header-wrapper">
      <button class="lg:hidden" @click="toggleIsOpen">
        <img src="/icons/side-bar-3bar.svg" class="w-[20px]" />
      </button>

      <div class="flex">
        <RouterLink to="/" class="lg:ml-[14px] lg:mr-[60px]">
          <img src="/logo/logo.svg" alt="CoMake" class="w-[80px] lg:w-[97px]" />
        </RouterLink>

        <div class="left-wrapper">
          <RouterLink to="/robots">
            <p class="PG1B" :class="menu === 'robots' ? 'text-gray-300' : 'text-gray-900'">
              Robots
            </p>
          </RouterLink>
          <RouterLink to="/control/puppy-pi">
            <p class="PG1B" :class="menu === 'control' ? 'text-gray-300' : 'text-gray-900'">
              Control
            </p>
          </RouterLink>
          <RouterLink to="/samples/broadcast/audio">
            <p class="PG1B" :class="menu === 'samples' ? 'text-gray-300' : 'text-gray-900'">
              Samples
            </p>
          </RouterLink>
          <RouterLink to="/usecase/mothCar">
            <p class="PG1B" :class="menu === 'usecase' ? 'text-gray-300' : 'text-gray-900'">
              Use case
            </p>
          </RouterLink>
          <RouterLink to="/co-drive/simulator">
            <p class="PG1B" :class="menu === 'co-drive' ? 'text-gray-300' : 'text-gray-900'">
              CoDrive
            </p>
          </RouterLink>
        </div>
      </div>

      <div class="opacity-0 lg:opacity-100">
        <RouterLink to="/contact" class="hidden lg:flex">
          <button
            class="contact-us PG3"
            :class="menu === 'contact' ? 'bg-green-100 text-white' : 'bg-white text-green-100'"
          >
            Contact Us
          </button>
        </RouterLink>
      </div>
    </nav>
  </header>
  <SideBarMobile :isOpen="isSideBarOpen" @close="toggleIsOpen" />
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import SideBarMobile from '@/components/navigation/SideBarMobile.vue'
import { useRoute } from 'vue-router'

const menu = ref<string>('robots')
const isSideBarOpen = ref<boolean>(false)
const route = useRoute()

onMounted(() => {
  menu.value = getMenuFromRoute()
})

watch(
  () => route,
  () => {
    menu.value = getMenuFromRoute()
  },
  { deep: true }
)

function getMenuFromRoute() {
  const path = window.location.pathname
  const category = path.split('/')[1]
  return category
}

const toggleIsOpen = () => {
  isSideBarOpen.value = !isSideBarOpen.value
}
</script>

<style scoped>
.header-wrapper {
  @apply fixed top-0 left-0 h-[55px] px-[16px] lg:h-[90px] lg:px-[40px] flex items-center justify-between z-[10000] bg-white opacity-95 w-full border-b border-[#E9E9E9];
}
.left-wrapper {
  @apply hidden lg:flex lg:items-center lg:gap-[37px];
}
.contact-us {
  @apply border border-green-100 rounded-full px-[22px] py-[10px] flex justify-between items-center;
}
.contact-us:hover {
  @apply text-white bg-green-200 border-green-200 transition-all duration-300;
}
</style>
