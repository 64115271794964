export const mainLink = [
  { to: '/robots', key: 'robots', text: 'Robots' },
  { to: '/control/puppy-pi', key: 'control', text: 'Control' },
  { to: '/samples/broadcast/audio', key: 'samples', text: 'Samples' },
  { to: '/usecase/mothCar', key: 'usecase', text: 'Use case' },
  { to: '/co-drive/drive', key: 'codrive', text: 'CoDrive' }
]

export const robotsLink = [
  { to: '/control/puppy-pi', name: 'puppy-pi', text: 'PuppyPi' },
  { to: '/control/spider-pi', name: 'spider-pi', text: 'SpiderPi' },
  { to: '/control/deep-think-car', name: 'deep-think-car', text: 'deepThinkCar' },
  { to: '/control/telescope', name: 'telescope', text: 'Telescope' },
  { to: '/control/rumi-car', name: 'rumi-car', text: 'RumiCar' },
  { to: '/control/moth-car', name: 'moth-car', text: 'MothCar' },
  { to: '/control/lego-cobot', name: 'lego-cobot', text: 'Lego Cobot' },
  // { to: '/control/pick-drop-cobot', text: 'Pick & Drop Cobot' },
  // { to: '/control/shoot-cobot', text: 'Shoot Cobot' },
  { to: '/control/scout-mini', text: 'Scout Mini' },
  // { to: '/control/tahara-scout-mini', text: 'Tahara' },
  // { to: '/control/dgcam001', name: 'hand-pi', text: 'dgcam001' }, // add in sidebar-nav menu
  { to: '/control/hand-pi', name: 'hand-pi', text: 'uHandPi' },
  {
    to: '/control/video-keyboard-control',
    name: 'video-keyboard-control',
    text: 'Video Keyboard Control'
  }
]

export const samplesLink = {
  Broadcast: [
    { to: '/samples/broadcast/audio', text: 'Audio' },
    { to: '/samples/broadcast/video', text: 'Video' },
    { to: '/samples/broadcast/broadcast', text: 'Broadcast' },
    { to: '/samples/broadcast/selectDevice', text: 'Select Devices' },
    { to: '/samples/broadcast/selectCodec', text: 'Select Codecs' },
    { to: '/samples/broadcast/selectResolution', text: 'Select Resolution' },
    { to: '/samples/broadcast/multiVideoPub', text: 'Multi Video(publish)' },
    { to: '/samples/broadcast/multiVideoSub', text: 'Multi Video(subscribe)' },
    { to: '/samples/broadcast/fileVideo', text: 'File Video' }
  ],
  Chat: [
    { to: '/samples/chat/messageChat', text: 'Message' },
    { to: '/samples/chat/fileChat', text: 'File' }
  ],
  'Control Type': [
    { to: '/samples/control/KeyboardControl', text: 'Keyboard' },
    { to: '/samples/control/GamepadControl', text: 'Gamepad' },
    { to: '/samples/control/SpeechControl', text: 'Speech' },
    { to: '/samples/control/SwarmControl', text: 'Swarm Control' },
    { to: '/samples/control/HandGestureControl', text: 'Hand Gesture (AI)' },
    { to: '/samples/control/HandPoseControl', text: 'Hand Pose (AI)' },
    { to: '/samples/control/FaceDetectionControl', text: 'Face Detection (AI)' }
  ],
  Applications: [
    { to: '/samples/applications/retryConnect', text: 'Retry Connect' },
    { to: '/samples/applications/sensor', text: 'Sensor' },
    { to: '/samples/applications/lidarVisualization', text: 'Lidar Visualization' },
    { to: '/samples/applications/360Video', text: '360° Video' }
  ],
  CoDrive: [
    { to: '/samples/codrive/carlaDriver', text: 'CARLA Driver' },
    { to: '/samples/codrive/carlaSpectator', text: 'CARLA Spectator' }
  ]
}

export const useCaseLink = [
  { to: '/usecase/mothCar', name: 'mothCar', text: 'MothCar' },
  { to: '/usecase/legoCar', name: 'legoCar', text: 'Lego Car' },
  { to: '/usecase/puppyPi', name: 'puppyPi', text: 'PuppyPi' },
  { to: '/usecase/kanavi', name: 'kanavi', text: 'Kanavi Mobility Lidar' },
  { to: '/usecase/demo', name: 'demo', text: 'Demo' },
  { to: '/usecase/demo360', name: 'demo360', text: 'Demo 360' }
]

export const codriveLink = [
  { to: '/co-drive/simulator', name: 'simulator', text: 'Simulator Remote Control' },
  {
    to: '/co-drive/simulator-driver',
    name: 'simulator-driver',
    text: 'Driver Simulator'
  },
  {
    to: '/co-drive/simulator-drone',
    name: 'simulator-drone',
    text: 'Drone Simulator'
  },
  { to: '/co-drive/smart', name: 'smart', text: 'Smart View' },
  { to: '/co-drive/surround', name: 'surround', text: 'Surround View' },
  { to: '/co-drive/around', name: 'around', text: 'Around View' },
  { to: '/co-drive/360-control', name: '360-control', text: '360° View Control' },
  { to: '/co-drive/group-control', name: 'group-control', text: 'Group Control' }
]
