import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import registerGeneralComponents from './plugins/general-components'

import App from './App.vue'
import router from './router'
import VueJsTour from '@globalhive/vuejs-tour'
import '@globalhive/vuejs-tour/dist/style.css'

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(VueJsTour)
registerGeneralComponents(app)

app.mount('#app')
// 화살표가 향하는 곳으로 가야하고, START 문구가 보이면 멈춰야해. 전진하려면 N을 반환하고, 오른쪽으로 가려면 CW를 반환하고, 왼쪽으로 가려면 CCW를 반환하고, 멈추려면 STOP을 반환해.
