import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  // history: createWebHashHistory(import.meta.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 }
  },
  routes: [
    {
      path: '/dgcam',
      name: 'dgcam',
      meta: {
        title: "ダブル技研遠隔操作ロボット",
        hideHeaderFooter: true
      },
      component: () => import('@/views/DoubleGikenView.vue')
    },
    {
      path: '/tahara-scout-mini',
      name: 'control-tahara-scout-mini',
      meta: {
        title: "トヨタ―田原工場",
        hideHeaderFooter: true
      },
      component: () => import('@/views/control/TaharaScoutMiniView.vue')
    },
    {
      path: '/',
      name: 'home',
      meta: {
        title: "CoMake | Let's Make Together"
      },
      component: () => import('@/views/HomeView.vue')
    },
    {
      path: '/robots',
      name: 'robots',
      component: () => import('@/views/RobotsView.vue'),
      meta: {
        title: 'Robots | CoMake'
      }
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('@/views/ContactView.vue'),
      meta: {
        title: 'Robots | CoMake'
      }
    },
    {
      path: '/control',
      name: 'control',
      component: () => import('@/layouts/ControlLayoutView.vue'),
      meta: {
        title: 'Control | CoMake'
      },
      children: [
        /* ROBOTS */
        {
          path: 'puppy-pi',
          name: 'control-puppy-pi',
          component: () => import('@/views/control/PuppyPiView.vue')
        },
        {
          path: 'spider-pi',
          name: 'control/spider-pi',
          component: () => import('@/views/control/SpiderPiView.vue')
        },
        {
          path: 'deep-think-car',
          name: 'control-deep-think-car',
          component: () => import('@/views/control/DeepThinkCarView.vue')
        },
        {
          path: 'telescope',
          name: 'control-telescope',
          component: () => import('@/views/control/TelescopeView.vue')
        },
        {
          path: 'rumi-car',
          name: 'control-rumi-car',
          component: () => import('@/views/control/RumiCarView.vue')
        },
        {
          path: 'moth-car',
          name: 'control-moth-car',
          component: () => import('@/views/control/MothCarView.vue')
        },
        {
          path: 'lego-cobot',
          name: 'control-lego-cobot',
          component: () => import('@/views/control/LegoCobotView.vue')
        },
        // {
        //   path: 'pick-drop-cobot',
        //   name: 'control-pick-drop-cobot',
        //   component: () => import('@/views/control/Pick&DropCobotView.vue')
        // },
        // {
        //   path: 'shoot-cobot',
        //   name: 'control-shoot-cobot',
        //   component: () => import('@/views/control/ShootCobotView.vue')
        // },
        {
          path: 'scout-mini',
          name: 'control-scout-mini',
          component: () => import('@/views/control/ScoutMiniView.vue')
        },
        {
          path: 'hand-pi', //robots페이지에 들어가야함
          name: 'control-hand-pi',
          component: () => import('@/views/control/HandPiView.vue')
        },
        {
          path: 'video-keyboard-control',
          name: 'control-video-keyboard-control',
          component: () => import('@/views/control/VideoKeyboardView.vue')
        }
      ]
    },
    {
      path: '/samples',
      name: 'samples',
      component: () => import('@/layouts/SamplesLayoutView.vue'),
      meta: {
        title: 'Samples | CoMake'
      },
      children: [
        /* BROADCAST */
        {
          path: 'broadcast/audio',
          name: 'samples-audio',
          component: () => import('@/views/samples/broadcast/AudioView.vue')
        },
        {
          path: 'broadcast/video',
          name: 'samples-video',
          component: () => import('@/views/samples/broadcast/VideoView.vue')
        },
        {
          path: 'broadcast/broadcast',
          name: 'samples-broadcast',
          component: () => import('@/views/samples/broadcast/BroadcastView.vue')
        },
        {
          path: 'broadcast/selectDevice',
          name: 'samples-selectDevice',
          component: () => import('@/views/samples/broadcast/SelectDeviceView.vue')
        },
        {
          path: 'broadcast/selectCodec',
          name: 'samples-selectCodec',
          component: () => import('@/views/samples/broadcast/SelectCodecView.vue')
        },
        {
          path: 'broadcast/selectResolution',
          name: 'samples-selectResolution',
          component: () => import('@/views/samples/broadcast/SelectResolutionView.vue')
        },
        {
          path: 'broadcast/multiVideoPub',
          name: 'samples-multiVideoPub',
          component: () => import('@/views/samples/broadcast/MultiVideoPubView.vue')
        },
        {
          path: 'broadcast/multiVideoSub',
          name: 'samples-multiVideoSub',
          component: () => import('@/views/samples/broadcast/MultiVideoSubView.vue')
        },
        {
          path: 'broadcast/fileVideo',
          name: 'samples-fileVideo',
          component: () => import('@/views/samples/broadcast/FileVideoView.vue')
        },
        {
          path: 'broadcast/videoTime',
          name: 'samples-videoTime',
          component: () => import('@/views/lab/VideoTimeView.vue')
        },
        /* CHAT */
        {
          path: 'chat/messageChat',
          name: 'samples-messageChat',
          component: () => import('@/views/samples/chat/MessageView.vue')
        },
        {
          path: 'chat/fileChat',
          name: 'samples-fileChat',
          component: () => import('@/views/samples/chat/FileView.vue')
        },
        /* CONTROL */
        {
          path: 'control/keyboardControl',
          name: 'samples-keyboardControl',
          component: () => import('@/views/samples/control/KeyboardView.vue')
        },
        {
          path: 'control/gamepadControl',
          name: 'samples-gamepadControl',
          component: () => import('@/views/samples/control/GamepadView.vue')
        },
        {
          path: 'control/speechControl',
          name: 'samples-speechControl',
          component: () => import('@/views/samples/control/SpeechView.vue')
        },
        {
          path: 'control/handGestureControl',
          name: 'samples-handGestureControl',
          component: () => import('@/views/samples/control/HandGestureView.vue')
        },
        {
          path: 'control/handPoseControl',
          name: 'samples-handPoseControl',
          component: () => import('@/views/samples/control/HandPoseView.vue')
        },
        {
          path: 'control/faceDetectionControl',
          name: 'samples-faceDetectionControl',
          component: () => import('@/views/samples/control/FaceDetectionView.vue')
        },
        {
          path: 'control/swarmControl',
          name: 'samples-swarmControl',
          component: () => import('@/views/samples/control/SwarmControlView.vue')
        },
        /* APPLICATIONS */
        {
          path: 'applications/imageCapture',
          name: 'samples-imageCapture',
          component: () => import('@/views/samples/applications/ImageCaptureView.vue')
        },
        {
          path: 'applications/retryConnect',
          name: 'samples-retryConnect',
          component: () => import('@/views/samples/applications/RetryConnectView.vue')
        },
        {
          path: 'applications/sensor',
          name: 'samples-sensor',
          component: () => import('@/views/samples/applications/SensorView.vue')
        },
        {
          path: 'applications/lidarVisualization',
          name: 'samples-lidarVisualization',
          component: () => import('@/views/samples/applications/LidarVisualizationView.vue')
        },
        {
          path: 'applications/360Video',
          name: 'samples-360Video',
          component: () => import('@/views/samples/applications/360VideoView.vue')
        },
        {
          path: 'codrive/carlaDriver',
          name: 'samples-carlaDriver',
          component: () => import('@/views/samples/codrive/CarlaDriverView.vue')
        },
        {
          path: 'codrive/carlaSpectator',
          name: 'samples-carlaSpectator',
          component: () => import('@/views/samples/codrive/CarlaSpectatorView.vue')
        }
      ]
    },
    {
      path: '/usecase',
      name: 'usecase',
      component: () => import('@/layouts/UseCaseLayoutView.vue'),
      meta: {
        title: 'Use Case | CoMake'
      },
      children: [
        {
          path: 'puppyPi',
          name: 'usecase-puppyPi',
          component: () => import('@/views/usecase/PuppyPiView.vue')
        },
        {
          path: 'mothCar',
          name: 'usecase-mothCar',
          component: () => import('@/views/usecase/MothCarView.vue')
        },
        {
          path: 'legoCar',
          name: 'usecase-legoCar',
          component: () => import('@/views/usecase/LegoCarView.vue')
        },
        {
          path: 'kanavi',
          name: 'usecase-kanavi',
          component: () => import('@/views/usecase/KanaviLidarView.vue')
        },
        {
          path: 'demo',
          name: 'usecase-demo',
          component: () => import('@/views/usecase/DemoView.vue')
        },
        {
          path: 'demo-pub',
          name: 'usecase-demo-pub',
          component: () => import('@/views/usecase/DemoPubView.vue')
        },
        {
          path: 'demo360',
          name: 'usecase-demo360',
          component: () => import('@/views/usecase/Demo360View.vue')
        }
      ]
    },
    {
      path: '/co-drive',
      name: 'co-drive',
      component: () => import('@/layouts/CoDriveLayoutView.vue'),
      meta: {
        title: 'CoDrive | CoMake'
      },
      children: [
        {
          path: 'simulator',
          name: 'codrive-simulator',
          component: () => import('@/views/codrive/SimulatorView.vue')
        },
        {
          path: 'simulator-driver',
          name: 'codrive-simulator-driver',
          component: () => import('@/views/codrive/DriverSimulatorView.vue')
        },
        {
          path: 'simulator-drone',
          name: 'codrive-simulator-drone',
          component: () => import('@/views/codrive/DroneSimulatorView.vue')
        },
        {
          path: 'smart',
          name: 'codrive-smart',
          component: () => import('@/views/codrive/SmartView.vue')
        },
        {
          path: 'surround',
          name: 'codrive-surround',
          component: () => import('@/views/codrive/SurroundView.vue')
        },
        {
          path: 'around',
          name: 'codrive-around',
          component: () => import('@/views/codrive/AroundView.vue')
        },
        {
          path: '360-control',
          name: 'codrive-360-control',
          component: () => import('@/views/codrive/360ViewControlView.vue')
        },
        {
          path: 'group-control',
          name: 'codrive-group-control',
          component: () => import('@/views/codrive/GroupControlView.vue')
        }
      ]
    },
    {
      path: '/lab',
      name: 'lab',
      component: () => import('@/layouts/LabLayoutView.vue'),
      children: [
        {
          path: '360Video',
          name: 'lab-360Video',
          component: () => import('@/views/lab/360VideoView.vue')
        },
        {
          path: 'recordVideo',
          name: 'lab-recordVideo',
          component: () => import('@/views/lab/RecordVideoView.vue')
        },
        {
          path: 'transcode',
          name: 'lab-transcode',
          component: () => import('@/views/lab/TranscodeView.vue')
        },
        {
          path: 'dragAndDropCanvas',
          name: 'lab-dragAndDropCanvas',
          component: () => import('@/views/lab/DragAndDropCanvasView.vue')
        },
        {
          path: 'graphicSend',
          name: 'lab-graphicSend',
          component: () => import('@/views/lab/GraphicDataSendView.vue')
        },
        {
          path: 'tracking',
          name: 'lab-tracking',
          component: () => import('@/views/lab/deviceTrackingView.vue')
        },
        {
          path: 'multiServer',
          name: 'lab-multiServer',
          component: () => import('@/views/lab/MultiServerView.vue')
        },
        {
          path: 'recordWebVideo',
          name: 'lab-recordWebVideo',
          component: () => import('@/views/lab/RecordWebVideoView.vue')
        },
        {
          path: 'webTransport',
          name: 'lab-webTransport',
          component: () => import('@/views/lab/WebTransportView.vue')
        },
        {
          path: 'serverTrafficCheck',
          name: 'lab-serverTrafficCheck',
          component: () => import('@/views/lab/ServerTrafficCheckView.vue')
        },
        {
          path: 'multiServerManagement',
          name: 'lab-multiServerManagement',
          component: () => import('@/views/lab/MultiServerManagementView.vue')
        },
        {
          path: 'mediaPacket',
          name: 'lab-mediaPacket',
          component: () => import('@/views/lab/MediaPacketView.vue')
        },
        {
          path: 'mimeTest',
          name: 'lab-mimeTest',
          component: () => import('@/views/lab/MimeTestView.vue')
        },
        {
          path: 'rateControlPub',
          name: 'lab-rateControlPub',
          component: () => import('@/views/lab/RateControlPubView.vue')
        },
        {
          path: 'rateControlSub',
          name: 'lab-rateControlSub',
          component: () => import('@/views/lab/RateControlSubView.vue')
        },
        {
          path: 'e2eSecurity',
          name: 'lab-e2eSecurity',
          component: () => import('@/views/lab/SecurePacketView.vue')
        },
        {
          path: 'autoResolutionPub',
          name: 'lab-autoResolutionPub',
          component: () => import('@/views/lab/AutoResolutionPubView.vue')
        },
        {
          path: 'autoResolutionSub',
          name: 'lab-autoResolutionSub',
          component: () => import('@/views/lab/AutoResolutionSubView.vue')
        },
        {
          path: 'termiteVideoPub',
          name: 'lab-termiteVideoPub',
          component: () => import('@/views/lab/TermiteVideoPubView.vue')
        }
      ]
    },
    {
      path: '/ai/cv',
      name: 'ai/cv',
      component: () => import('@/layouts/CVLayoutView.vue'),
      children: [
        {
          path: 'faceDetection',
          name: 'cv-faceDetection',
          component: () => import('@/views/ai/cv/FaceDetectionView.vue')
        },
        {
          path: 'handGestureRecognition',
          name: 'cv-handGestureRecognition',
          component: () => import('@/views/ai/cv/HandGestureRecognitionView.vue')
        },
        {
          path: 'handLandmarkDetection',
          name: 'cv-handLandmarkDetection',
          component: () => import('@/views/ai/cv/HandLandmarkDetectionView.vue')
        },
        {
          path: 'objectDetection',
          name: 'cv-objectDetection',
          component: () => import('@/views/ai/cv/ObjectDetectionView.vue')
        },
        {
          path: 'videoObjectDetection',
          name: 'cv-videoObjectDetection',
          component: () => import('@/views/ai/cv/VideoObjectDetectionView.vue')
        },
        {
          path: 'backgroundRemover',
          name: 'cv-backgroundRemover',
          component: () => import('@/views/ai/cv/BackgroundRemoverView.vue')
        }
      ]
    },
    {
      path: '/ai/llm',
      name: 'ai/llm',
      component: () => import('@/layouts/LLMLayoutView.vue'),
      children: [
        {
          path: 'webllm',
          name: 'llm-webllm',
          component: () => import('@/views/ai/llm/WebLLMView.vue')
        },
        {
          path: 'gemini',
          name: 'llm-gemini',
          component: () => import('@/views/ai/llm/GeminiView.vue')
        },
        {
          path: 'gpt',
          name: 'llm-gpt',
          component: () => import('@/views/ai/llm/GPTView.vue')
        },
        {
          path: 'claude',
          name: 'llm-claude',
          component: () => import('@/views/ai/llm/ClaudeView.vue')
        }
      ]
    },
    {
      path: '/ai/vlm',
      name: 'ai/vlm',
      component: () => import('@/layouts/VLMLayoutView.vue'),
      children: [
        {
          path: 'gpt',
          name: 'vlm-gpt',
          component: () => import('@/views/ai/vlm/GPTView.vue')
        },
        {
          path: 'gemini',
          name: 'vlm-gemini',
          component: () => import('@/views/ai/vlm/GeminiView.vue')
        }
        // {
        //   path: 'nanoLLaVA',
        //   name: 'vlm-nanoLLaVA',
        //   component: () => import('@/views/ai/vlm/nanoLLaVAView.vue')
        // }
      ]
    },
    {
      path: '/design',
      name: 'design',
      component: () => import('@/layouts/DesignSystemLayoutView.vue'),
      children: [
        {
          path: 'text',
          name: 'design-text',
          component: () => import('@/views/design/TextView.vue')
        },
        {
          path: 'button',
          name: 'design-button',
          component: () => import('@/views/design/ButtonView.vue')
        },
        {
          path: 'card',
          name: 'design-card',
          component: () => import('@/views/design/CardView.vue')
        },
        {
          path: 'accordion',
          name: 'design-accordion',
          component: () => import('@/views/design/AccordionView.vue')
        },
        {
          path: 'toast',
          name: 'design-toast',
          component: () => import('@/views/design/ToastView.vue')
        }
      ]
    },
    {
      path: '/:catchAll(.*)*',
      name: 'not-found',
      component: () => import('../views/NotFoundView.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title as string
  } else {
    document.title = "CoMake | Let's Make Together"
  }
  next()
})

export default router
